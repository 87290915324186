@import '~antd/dist/antd.css';

.ant-select-dropdown, .ant-picker-dropdown {
    z-index: 10000;
}

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: inherit;
    text-decoration: none; /* no underline */
}

